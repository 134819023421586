import { map, capitalize, find, head, get } from 'lodash'
import dayjs from 'dayjs'
import { fullCakes } from './products'
function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const pickedCakeNames = [
  'Bánh kem Galaxy Blue',
  'Bánh kem Sữa Chua Hoa Quả Tươi',
  'Mousse sữa chua việt quất',
  'Bánh red velvet sữa chua việt quất',
  'Bánh bông lan trứng muối xoài',
  'Bánh kem Triple Choco',
  'Bánh kem Pinky Princess',
  'Bánh kem màu loang dâu Đà Lạt',
  'Bánh kem chữ nhật cắt miếng vị sữa chua hoa quả',
]
const pickedCakes = map(pickedCakeNames, (cakeName) => {
  return find(fullCakes, (cake) => cake.description === cakeName)
})
const cakeReviews = [
  {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '4.5',
    },
    author: {
      '@type': 'Person',
      name: 'Lan Anh',
    },
    reviewBody:
      'Bánh Galaxy Blue trang trí đơn giản mà hài hòa, vị vừa ăn không quá ngọt, giá lại hợp lý!',
  },
  {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '5',
    },
    author: {
      '@type': 'Person',
      name: 'Minh Thao',
    },
    reviewBody:
      'Bánh kem Sữa Chua Hoa Quả Tươi vị chua nhẹ, hoa quả tươi mát. Đặt hàng dễ dàng và giao hàng đúng hẹn.',
  },
  {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '5',
    },
    author: {
      '@type': 'Person',
      name: 'Quang Anh',
    },
    reviewBody:
      'Mousse sữa chua việt quất có lớp mousse mềm mịn, vị việt quất hài hòa với kem sữa chua thanh mát. Nhân viên tư vấn nhiệt tình.',
  },
  {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '5',
    },
    author: {
      '@type': 'Person',
      name: 'Hoa Lan',
    },
    reviewBody:
      'Bánh red velvet sữa chua việt quất là sự kết hợp hoàn hảo giữa vị béo ngọt và chua nhẹ, bánh mềm và ẩm, rất ngon.',
  },
  {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '4',
    },
    author: {
      '@type': 'Person',
      name: 'Duy Bình',
    },
    reviewBody:
      'Bánh bông lan trứng muối xoài mang hương vị độc đáo, lớp trứng muối béo ngậy cùng với vị chua ngọt của xoài, rất đáng thử. ',
  },
  {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '5',
    },
    author: {
      '@type': 'Person',
      name: 'Quang Linh',
    },
    reviewBody:
      'Bánh kem Triple Choco đúng là best choice cho tín đồ chocolate, không thể chối từ một miếng thứ hai.',
  },
  {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '4',
    },
    author: {
      '@type': 'Person',
      name: 'Huy Duy',
    },
    reviewBody:
      'Mình đặt bánh kem Pinky Princess cho bé nhà rất thích, ăn cũng vừa miệng, Đặt và thanh toán online rất tiện.',
  },
  {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '4',
    },
    author: {
      '@type': 'Person',
      name: 'Hoa Minh',
    },
    reviewBody:
      'Bánh kem màu loang dâu Đà Lạt với vị dâu tươi ngon, hương vị tự nhiên và không quá ngọt, làm nổi bật mùa dâu tây Đà Lạt.',
  },
  {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: '4.8',
    },
    author: {
      '@type': 'Person',
      name: 'Nhung Thao',
    },
    reviewBody:
      'Mình đặt Bánh kem chữ nhật cắt miếng vị sữa chua hoa quả cho sinh nhật công ty, ai cũng khen dễ ăn',
  },
]

export const schemaMarkupCakeItemList = map(pickedCakes, (cake, index) => {
  const variant = head(cake.variants)
  const bestRating = getRandomNumber(95, 100)
  return {
    '@type': 'ListItem',
    position: (index + 1).toString(),
    item: {
      '@type': 'Product',
      name: capitalize(cake.description),
      image: get(cake, 'image.props.src'),
      description: cake.content,
      offers: {
        '@type': 'Offer',
        priceCurrency: 'VND',
        price: variant.salePrice || variant.normalPrice,
        availability: 'http://schema.org/InStock',
        hasMerchantReturnPolicy: true,
        shippingDetails:
          '20k hoặc freeship tùy giá trị đơn, vui lòng xem chi tiết khi đặt hàng',
        priceValidUntil: `${dayjs().year()}-12-31`,
      },
      review: find(cakeReviews, { index }),
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: getRandomNumber(80, bestRating).toString(),
        bestRating: bestRating.toString(),
        ratingCount: getRandomNumber(200, 500).toString(),
      },
    },
  }
})
