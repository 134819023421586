import * as React from 'react'
import tw from 'twin.macro'
import { map } from 'lodash'
import { BorderBox, Heading2 } from './Base'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image'
import { Icon } from 'semantic-ui-react'
import Slider from 'react-slick'
import { useRef } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import * as styles from './Styles/SectionCombo.module.scss'
import { currencyFormatter } from '../../utils/utils'
// import useMediaQuery from '../../hooks/useMediaQuery'

const ComboHeading2 = tw(
  Heading2
)`relative z-[100] text-savor-cake-primary-green-avocado-0 text-5xl`

const ComboBorderBox = tw(
  BorderBox
)`mt-0 mx-auto mb-10 bg-savor-cake-primary-green-avocado-0`

const StyledSlide = styled.div`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`

const CakeName = styled.div`
  font-family: 'Lexend' sans-serif !important;
  border: 2px solid #61a05c;
  border-radius: 5px;
  padding: 1.5rem 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #61a05c;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  @media only screen and (min-width: 1024px) {
    width: calc((100vw - 10rem) / 2);
    font-size: 2rem;
  }
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const AnimatedText = function ({ text, fontType }) {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={text ? text : 'empty'}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
        css={
          fontType === 'primary'
            ? tw`font-savor-cake-primary-lexend!`
            : tw`font-savor-cake-secondary-quicksand!`
        }
      >
        {text ? text : '😋'}
      </motion.div>
    </AnimatePresence>
  )
}

const CakeNameContainer = styled.div`
  padding: 20px;
  display: flex;
  margin: 10px 0 10px;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

const PriceContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #61a05c;
  font-size: 1.5rem;
`

const PriceGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
`

const Price = styled.div`
  display: block;
  font-weight: bold;
  font-size: 2rem;
  color: #61a05c;
  ${(props) =>
    props.normalPrice
      ? `
    font-weight: normal;
    font-size: 1.2rem;
    text-decoration: line-through;
  `
      : ``}
`

const SliderContainer = styled.div`
  width: 100%;
  padding: 0 40px;
`

export const comboSliderSettings = {
  className: styles.sectionComboSlider,
  slidesToShow: 3,
  speed: 500,
  dots: false,
  arrows: true,
  // autoplay: true,
  // autoplaySpeed: 5000,
  // pauseOnHover: true,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const SectionCombo = React.forwardRef(function (props, ref) {
  const data = useStaticQuery(graphql`
    {
      allCombosJson {
        edges {
          node {
            id
            product1
            product2
            normalPrice
            salePrice
            image {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
      }
    }
  `)

  const [combo, setCombo] = React.useState(data.allCombosJson.edges[0].node)
  const slider = useRef(null)

  const settings = {
    ...comboSliderSettings,
    centerMode: true,
    centerPadding: '50px',
    focusOnSelect: true,
    beforeChange: (current, next) => {
      setCombo(data.allCombosJson.edges[next].node)
    },
  }

  // const isMediumScreen = useMediaQuery('(min-width: 768px)')

  return (
    <div id="scake-savor-cake-main-combo-section">
      {/* {!isMediumScreen && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/combo-top-wave-mobile.png"
          alt="wave"
          layout="fullWidth"
        />
      )}
      {isMediumScreen && (
      
      )} */}
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/combo-top-wave-thin.png"
        alt="wave"
        layout="fullWidth"
      />
      <div
        ref={ref}
        tw="bg-savor-cake-primary-mint-0 relative px-3 pt-10 pb-10"
      >
        <ComboHeading2>Combo 2 bánh sinh nhật</ComboHeading2>
        <ComboBorderBox></ComboBorderBox>
        <p tw="text-savor-cakes-grey-500 text-center text-lg font-savor-cake-secondary-quicksand">
          Kết hợp ngay hai chiếc bánh size nhỏ để tạo nên combo 2 bánh phong phú
          hương vị với mức giá khuyến mãi hấp dẫn!
        </p>
        <CakeNameContainer>
          <CakeName>
            <AnimatedText
              fontType={'primary'}
              text={combo.product1}
            ></AnimatedText>
          </CakeName>
          <div
            style={{
              margin: 'auto auto',
            }}
          >
            <Icon
              name="plus"
              style={{ margin: 0, color: '#61A05C' }}
              inverted
            ></Icon>
          </div>
          <CakeName>
            <AnimatedText
              fontType={'primary'}
              text={combo.product2}
            ></AnimatedText>
          </CakeName>
        </CakeNameContainer>
        <SliderContainer>
          <Slider {...settings} ref={slider}>
            {data &&
              map(data.allCombosJson.edges, (node) => {
                const combo = node.node
                const image = getImage(combo.image)
                return (
                  <div key={combo.id}>
                    <StyledSlide>
                      <GatsbyImage image={image} alt={combo.product1} />
                    </StyledSlide>
                  </div>
                )
              })}
          </Slider>
        </SliderContainer>

        <PriceContainer>
          <div tw="font-savor-cake-secondary-quicksand">Giá chỉ còn</div>
          <PriceGroup>
            <Price normalPrice>
              <AnimatedText
                text={currencyFormatter.format(combo.normalPrice)}
              ></AnimatedText>
            </Price>{' '}
            <Price>
              <AnimatedText
                text={currencyFormatter.format(combo.salePrice)}
              ></AnimatedText>
            </Price>
          </PriceGroup>
        </PriceContainer>
      </div>
    </div>
  )
})

export default SectionCombo
