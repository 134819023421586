import React, { forwardRef, useEffect, useContext } from 'react'
import tw, { styled } from 'twin.macro'
import { map } from 'lodash'
import { Container } from 'semantic-ui-react'
import { StaticImage } from 'gatsby-plugin-image'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { revealVariants, buttonVariants } from '../../utils/motion'
import useMediaQuery from '../../hooks/useMediaQuery'
import { CakeOrder__Desktop, CakeOrder__Mobile } from '../../utils/cakeOrder'
import {
  Description,
  Heading2,
  InboxText,
  Line,
  SectionContainer,
} from './Base'
import { ProductImage } from '../Base/ProductGroup/ProductCardElems'
import { ImageDataContext } from '../../states/imageDataContext'
import { customizedProducts } from '../../utils/products'
import { getNodeNameByData } from '../../utils/utils'
import { BubbleTopRight } from '../Locations'

const AdjustedHeading2 = styled(Heading2)`
  @media only screen and (min-width: 1024px) {
    font-size: 42px;
    text-align: left;
  }
`

const CakeOrderTitle = styled.h3`
  color: #2f2105 !important;
  font-family: 'Lexend', sans-serif;
  font-weight: 700;
  line-height: 200%;
  font-size: 20px;
  @media only screen and (min-width: 1024px) {
    font-size: 36px;
  }
`
const FlavorContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  .flavorCard {
    flex: 0 0 calc(25% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      width: 25%;
      min-width: 62px;
      background: #afd4a5;
      box-shadow: 14px 32px 75px -35px rgba(9, 101, 58, 0.14);
      border-radius: 100px 100px 100px 0px;
      padding: 10px;
      margin-bottom: -30px;
    }
  }
`
const OrderBtn = styled.a`
  padding: 6px 0;
  width: 40%;
  min-width: 260px;
  margin: 60px auto 30px;
  background: linear-gradient(to right, #fbd745, #fcae16);
  border-radius: 10rem;
  display: block;
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    margin: 4px 4px;
    border: 1px solid #f3ad22;
    border-radius: 10rem;
  }
`

const CakeStyleImg = styled.div`
  width: 75%;
  min-width: 100px;
  min-height: 100px;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid #fff;
  object-fit: cover;
  border-radius: 50%;
`
const FlavorSection = (props) => {
  const flavorView = props.isDesktop ? CakeOrder__Desktop : CakeOrder__Mobile
  return (
    <>
      {map(flavorView, (flavorSection) => (
        <div key={flavorSection.title}>
          <CakeOrderTitle>{flavorSection.title}</CakeOrderTitle>
          <FlavorContainer>
            {map(flavorSection.orders, (flavorItem) => (
              <div className="flavorCard" key={flavorItem.key}>
                <span>{flavorItem.image}</span>
                <Description tw="my-8!">{flavorItem.content}</Description>
              </div>
            ))}
          </FlavorContainer>
        </div>
      ))}
    </>
  )
}

const CakeTypeCard = ({ product }) => {
  // const [parentHeight, setParentHeight] = useState('20rem')
  // const childRef = useRef(null)

  // useEffect(() => {
  //   function handleResize() {
  //     if (childRef.current) {
  //       let ratio = 0.9
  //       setParentHeight(childRef.current.offsetHeight * ratio)
  //     }
  //   }
  //   // Call handleResize initially in case the screen is already resized
  //   handleResize()
  //   // Add event listener for resize event
  //   window.addEventListener('resize', handleResize)
  //   // Remove event listener on cleanup
  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])
  const imageData = useContext(ImageDataContext)
  const nodeName = getNodeNameByData(imageData)

  return (
    <div
      tw=" flex flex-col justify-center items-center relative bg-savor-cake-secondary-green-matcha-100 rounded-lg
    px-2 w-2/3 m-auto
    h-40 xs:h-44 sm:h-48 md:h-52 lg:h-44"
      // style={{ height: parentHeight }}
    >
      <div
        // ref={childRef}
        tw="flex flex-col justify-center items-center w-full text-center
      absolute -top-10 xs:-top-14 sm:-top-16 lg:-top-14 xl:-top-16"
      >
        <CakeStyleImg tw=" border-savor-cake-secondary-green-matcha-100">
          {/* {product.image} */}
          <ProductImage
            product={product}
            data={imageData}
            nodeName={nodeName}
            imageStyle="round"
          />
        </CakeStyleImg>
        <p
          tw="text-savor-cake-primary-green-avocado-100 font-poppinsSansSerif font-extrabold
          text-2xl xs:text-3xl md:text-4xl my-2 "
        >
          {product.content}
        </p>
        <p
          tw="capitalize font-normal 
        text-base xs:text-lg md:text-xl font-savor-cake-secondary-quicksand"
        >
          {product.description}
        </p>
      </div>
    </div>
  )
}
const CakeTypeSection = () => {
  return (
    <div tw="">
      <CakeOrderTitle>Lựa chọn kiểu bánh</CakeOrderTitle>
      <div
        tw="w-2/3 mx-auto flex justify-center items-center 
        gap-4 xs:gap-5 lg:gap-6
        px-1 pt-10 xs:pt-12 lg:pt-14 xl:pt-16
         pb-5 md:pb-10 xs:px-4 sm:px-6"
      >
        {map(customizedProducts, (product) => {
          return <CakeTypeCard key={product.id} product={product} />
        })}
      </div>
    </div>
  )
}

const CakeSizeCard = tw.div`flex flex-col justify-end items-center`

const CakeSizeName = tw.p`text-savor-cake-primary-green-avocado-100 text-xl font-bold font-savor-cake-secondary-quicksand uppercase text-center mt-3 sm:mt-4 md:mt-5`

const cakeSizes = [
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/bento-sz.png"
        alt="Kích thước size bento"
      />
    ),
    name: 'Size bento',
  },
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/mini-sz.png"
        alt="Kích thước size mini"
      />
    ),
    name: 'Size mini',
  },
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/nho-sz.png"
        alt="Kích thước size nhỏ"
      />
    ),
    name: 'Size nhỏ',
  },
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/vua-sz.png"
        alt="Kích thước size vừa"
      />
    ),
    name: 'Size vừa',
  },
]

const SectionKorea = forwardRef((props, ref) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const isLargeDesktop = useMediaQuery('(min-width: 1650px)')
  const controls = useAnimation()
  const [_ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const calculateWidthOfImage = () => {
    return isLargeDesktop ? 42 : 46
  }
  const isMediumScreen = useMediaQuery('(min-width: 768px)')

  return (
    <motion.div
      ref={_ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      {!isMediumScreen && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/product-bot-wave-mobile.png"
          alt="wave"
          layout="fullWidth"
        />
      )}
      {isMediumScreen && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/product-bot-wave.png"
          alt="wave"
          layout="fullWidth"
        />
      )}
      <SectionContainer
        id="scake-savor-cake-main-order-section"
        ref={ref}
        tw="pb-0 pt-5"
      >
        <div style={{ position: 'relative', marginBottom: '2%' }}>
          {isDesktop ? (
            <>
              <StaticImage
                src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/staff-2023-08.png"
                alt="Nhân viên cùng hộp bánh Savor Cake"
                placeholder="blurred"
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  width: `${calculateWidthOfImage()}%`,
                }}
              />
              <BubbleTopRight
                customStyle={{
                  top: '-25%',
                  zIndex: 10,
                }}
              />
            </>
          ) : null}

          <Container>
            <div
              tw="relative"
              style={{ maxWidth: `${isDesktop ? '55%' : '100%'}` }}
            >
              <div tw="relative right-[-25%] flex flex-col items-center mt-8 mb-6">
                <AdjustedHeading2>Bánh Vẽ - Bánh Order</AdjustedHeading2>
                <Line />
                <Description tw="mx-0 w-full">
                  Bạn muốn có một chiếc bánh thật đặc biệt, mang nét riêng
                  “không đụng hàng” để tặng người thân, bạn bè. Hãy gửi ý tưởng
                  và bọn mình sẽ giúp bạn làm điều đó!
                </Description>
              </div>
              {/* kiểu bánh */}
              <CakeTypeSection />
              {/* vị bánh */}
              <FlavorSection
                style={{ marginTop: '-40px !important' }}
              ></FlavorSection>
            </div>
          </Container>
        </div>
      </SectionContainer>
      {/* cỡ bánh */}
      <Container tw="mb-3">
        <CakeOrderTitle>Lựa chọn size bánh</CakeOrderTitle>
      </Container>
      <SectionContainer tw="pt-2 px-2 md:pt-5 lg:pt-0">
        <Container>
          <div tw="grid gap-4 lg:gap-2 grid-cols-2 grid-rows-2 md:grid-cols-4 md:grid-rows-1 lg:max-w-screen-lg m-auto">
            {/* bento */}
            <CakeSizeCard>
              <div tw="">{cakeSizes[0].image}</div>
              <CakeSizeName>{cakeSizes[0].name}</CakeSizeName>
            </CakeSizeCard>

            {/* mini */}
            <CakeSizeCard>
              <div tw="">{cakeSizes[1].image}</div>
              <CakeSizeName>{cakeSizes[1].name}</CakeSizeName>
            </CakeSizeCard>
            {/* nhỏ */}
            <CakeSizeCard>
              <div tw="">{cakeSizes[2].image}</div>
              <CakeSizeName>{cakeSizes[2].name}</CakeSizeName>
            </CakeSizeCard>
            {/* vừa */}
            <CakeSizeCard>
              <div tw="">{cakeSizes[3].image}</div>
              <CakeSizeName>{cakeSizes[3].name}</CakeSizeName>
            </CakeSizeCard>
          </div>
          {/* Button */}
          <motion.div variants={buttonVariants} whileHover="hover">
            <OrderBtn
              onClick={() => {
                if (window.gtag) {
                  window.gtag('event', 'select_promotion', {
                    event_label: 'bsn_section_korea',
                    creative_name: 'bsn_section_korea',
                  })
                }
              }}
              href="https://m.me/savor.vn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InboxText>inbox đặt bánh</InboxText>
            </OrderBtn>
          </motion.div>
        </Container>
      </SectionContainer>
    </motion.div>
  )
})

export default SectionKorea
