import React from 'react'
import tw from 'twin.macro'
import _ from 'lodash'
import { StaticImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
// import ImageZoom from 'react-medium-image-zoom'

import { Heading2, Description, Line, SectionContainer } from './Base'
import { containerStyle, StaticImgAbsoluteStyle } from '../Base'
import { comboSliderSettings } from './SectionCombo'
import * as introStyles from '../../assets/scss/intro-section-landing-btt.module.scss'
import '../../styles/staticImageInlineStyles.css'
import ProductGroups from './ProductGroups'
import { newFast1hCollection } from '../../utils/products'
import useMediaQuery from '../../hooks/useMediaQuery'

const BubbleTopRight = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/bubbleLeftBot.png"
      alt="bubble"
      placeholder="blurred"
      layout="fullWidth"
      style={{
        ...StaticImgAbsoluteStyle,
        width: '25%',
        left: 0,
        top: '-0.5rem',
      }}
    />
  )
}

const HorizontalImage1 = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/ship/shipperRow1.png"
    alt="shipper image"
    placeholder="blurred"
    style={{ height: '100%' }}
  />
)
const HorizontalImage2 = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/ship/shipperRow2.png"
    alt="shipper image"
    placeholder="blurred"
    style={{ height: '100%' }}
  />
)
const HorizontalImage3 = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/ship/shipperRow3.png"
    alt="shipper image"
    placeholder="blurred"
    style={{ height: '100%' }}
  />
)
const HorizontalImage4 = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/ship/shipperRow4.png"
    alt="shipper image"
    placeholder="blurred"
    style={{ height: '100%' }}
  />
)

const VerticalImage = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/ship/shipperCol.png"
    alt="shipper image"
    placeholder="blurred"
    style={{ height: '100%' }}
  />
)
const generateMapId = (() => {
  let counter = 0
  return () => counter++
})()
const shipImages = _.map(
  [
    {
      image: HorizontalImage1,
    },
    {
      image: HorizontalImage2,
    },
    {
      image: HorizontalImage3,
    },
    {
      image: HorizontalImage4,
    },
    //  {
    //    image: VerticalImage,
    //    mobileSkip: true
    //  },
  ],
  (imageItem) => {
    imageItem.id = generateMapId()
    return imageItem
  }
)
const Horizontal = tw.div`md:(col-span-1 row-span-1)`
const Vertical = tw.div`md:(col-span-1 row-span-2)`
const ShipperShowcase = () => {
  const settings = {
    ...comboSliderSettings,
    speed: 300,
    className: introStyles.introPartial,
  }

  return (
    <>
      <div
        tw="hidden
        z-10 max-w-5xl bg-transparent relative
        mx-2 sm:mx-5 lg:mx-auto
        md:(grid grid-cols-3 grid-flow-row-dense gap-3)
        "
      >
        <Vertical tw=" md:(row-start-1 col-start-2)">{VerticalImage}</Vertical>
        <Horizontal tw="md:row-start-1 md:col-start-1">
          {HorizontalImage1}
        </Horizontal>
        <Horizontal tw="md:row-start-2 md:col-start-1">
          {HorizontalImage2}
        </Horizontal>
        <Horizontal tw="md:row-start-1 md:col-start-3">
          {HorizontalImage3}
        </Horizontal>
        <Horizontal tw="bg-savor-cakes-grey-200 md:row-start-2 md:col-start-3">
          {HorizontalImage4}
        </Horizontal>
      </div>
      <div tw="block md:hidden!">
        <Slider {...settings}>
          {shipImages &&
            _.map(shipImages, (imageItem) => {
              return <div key={imageItem.id}>{imageItem.image}</div>
            })}
        </Slider>
      </div>
    </>
  )
}

const BodyShipperSection = () => {
  const isMediumScreen = useMediaQuery('(min-width: 768px)')
  return (
    <section tw="relative z-10 h-full pb-2" id="ship">
      {/* wave + container */}
      {!isMediumScreen && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/product-top-wave-mobile.png"
          alt="wave"
          layout="fullWidth"
          placeholder="blurred"
        />
      )}
      {isMediumScreen && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/product-top-wave.png"
          alt="wave"
          layout="fullWidth"
          placeholder="blurred"
        />
      )}
      <div css={[containerStyle, tw`lg:max-w-screen-xl!`]}>
        <Heading2>"Biệt đội" Ship hỏa tốc</Heading2>
        <Line center={true} />
        <Description tw="md:px-10">
          Savor Cake xây dựng đội ngũ Shipper chuyên nghiệp & thân thiện, giao
          hàng nhanh chóng đến tay khách yêu trong vòng 1H
        </Description>
        <ShipperShowcase />
        <ProductGroups products={newFast1hCollection} />
      </div>
    </section>
  )
}

const ShipSection = () => {
  return (
    <SectionContainer background="#FEFBF0">
      {/* sub */}
      <BubbleTopRight />
      {/* main */}
      <BodyShipperSection />
    </SectionContainer>
  )
}
export default ShipSection
