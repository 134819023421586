import tw from 'twin.macro'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import CollectionSlider from '../ProductSlider/CollectionSlider'
import { successToast } from '../../utils/toastify'
import { mousseCollection, fruitCollection } from '../../utils/products'
import { siteUrl } from '../../utils/constants'

const collections = {
  mousse: {
    group: 'mousse',
    description:
      'Savor ra mắt bộ sưu tập bánh mousse ngọt mềm, thơm lừng vị hoa quả/cà phê. Bánh sử dụng nguyên liệu xịn xò, 100% kem tươi whipping nhập khẩu và hoa quả tươi, phù hợp với những người sành ăn nhất',
    bannerImage: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/mousse-collection-cakes.png"
        alt="banner bộ sưu tập bánh mousse"
        placeholder="blurred"
      />
    ),
    leftToRight: true,
    products: mousseCollection,
  },
  chocolate: {
    group: 'chocolate',
    description:
      'Bạn có biết socola không những giúp giảm lo âu, căng thẳng, mà còn làm tăng năng lượng, giúp làn da khỏe đẹp. Mùa đông này nhâm nhi một chiếc bánh socola còn làm trái tim ta tan chảy',
    bannerImage: (
      <StaticImage
        src="../../assets/images/landing-page-bsn/special-collection/socola-collection.jpg"
        alt="banner socola collection"
        placeholder="blurred"
      />
    ),
    leftToRight: false,
    products: [],
  },
  fruit: {
    group: 'fresh-fruit',
    description:
      'Bộ sưu tập bánh kem, bánh sinh nhật hoa quả tươi cực ngon miệng. Bánh sử dụng các loại hoa quả tươi: dâu tây, việt quất, nho Mỹ xanh, xoài. Trang trí bắt mắt, hương vị đa dạng, tươi ngon mỗi ngày.',
    bannerImage: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/fruit-collection-cakes.png"
        alt="banner-banh-hoa-qua-collection"
        placeholder="blurred"
      />
    ),
    leftToRight: false,
    products: fruitCollection,
  },
}

const HeadLine = tw.div`rounded-md w-16 h-1 md:h-2 md:w-24 mx-auto mt-2 bg-savor-cake-primary-green-avocado-0`
const TitleStyle = tw`rounded font-savor-cake-primary-lexend leading-10 text-3xl font-bold md:text-5xl`

const MousseTitle = () => {
  return (
    <>
      <h2
        id="mousse"
        tw="font-savor-cake-primary-lexend leading-10 text-3xl font-bold text-savor-cake-primary-green-avocado-0 md:text-5xl"
      >
        Bộ sưu tập bánh lạnh
        <br />
        <span tw="text-savor-cake-primary-yellow-banana-100">Mousse</span>
      </h2>
      <HeadLine tw="md:mr-0" />
    </>
  )
}
const FruitTitle = () => {
  return (
    <>
      <h2
        id="fresh-fruit"
        tw="text-savor-cake-primary-green-avocado-0 "
        css={TitleStyle}
      >
        Bánh kem hoa quả tươi <br />
        <span tw="block pt-2 font-savor-cake-primary-lexend text-savor-cake-primary-yellow-banana-100">
          Vị ngon mỗi ngày
        </span>
      </h2>
      <HeadLine tw="md:ml-0" />
    </>
  )
}

const SocolaTitle = () => {
  return (
    <>
      <h2 id="chocolate" tw="text-savor-cakes-brown-500" css={TitleStyle}>
        Nhẹ nhàng Đông đến
        <br />
        Ngọt ngào vị
        <span tw="text-savor-cakes-orange-900"> Chocolate</span>
      </h2>
    </>
  )
}
const Banner = ({ data }) => {
  let title

  switch (data.group) {
    case 'mousse':
      title = <MousseTitle />
      break
    case 'socola':
      title = <SocolaTitle />
      break
    case 'fresh-fruit':
      title = <FruitTitle />
      break
    default:
      break
  }
  return (
    <section tw=" grid grid-cols-1 md:grid-cols-2 md:grid-rows-1 pt-8 md:pt-0">
      <div tw=" row-span-1 col-span-1 flex justify-center items-center">
        <div tw="w-full">{data.bannerImage}</div>
      </div>
      <div
        css={[
          tw` px-2 row-start-1 row-end-2 col-span-1 flex justify-center items-center flex-col text-center space-y-8 pb-4 md:pb-0 `,
          data.leftToRight
            ? tw`md:col-start-2 md:col-end-3 md:text-right md:items-end`
            : tw`md:col-start-1 md:col-end-2 md:text-left md:items-start`,
        ]}
      >
        <div>
          <div>{title}</div>
        </div>
        <p tw="text-savor-cakes-grey-100 font-savor-cake-secondary-quicksand text-base font-semibold md:text-xl">
          {data.description}
        </p>
      </div>
    </section>
  )
}
const ProductGroups = ({ products, group, showId }) => {
  if (group === 'fresh-fruit') {
    showId = 'scake-savor-cake-main-fruit-collection'
  }
  return (
    <div tw="pt-4 md:pt-6" id={showId}>
      <h3
        css={[
          tw` font-savor-cake-primary-lexend md:text-4xl text-savor-cakes-grey-200`,
          group === 'mousse' ? tw`md:mr-0` : tw`md:ml-0`,
        ]}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span
          tw="cursor-pointer"
          role="button"
          tabindex="-1"
          onClick={() => {
            const url = `${siteUrl}?viewId=${showId}`
            navigator.clipboard.writeText(url)
            successToast('Link Copied', {
              autoClose: 600,
              hideProgressBar: true,
              pauseOnHover: false,
            })
          }}
        >
          Danh mục bánh
        </span>
      </h3>
      <div tw="lg:max-w-6xl mx-auto">
        <div tw="">
          <CollectionSlider products={products} group={group} />
        </div>
      </div>
    </div>
  )
}
const CollectionSection = ({ variant, haveWave, id, showId }) => {
  let data
  let bgColor
  switch (variant) {
    case 'mousse':
      data = collections.mousse
      break

    case 'fresh-fruit':
      data = collections.fruit
      bgColor = '#FEFBF0'
      break

    default:
      break
  }
  return (
    <div
      tw="max-w-4xl lg:max-w-6xl xl:max-w-7xl mx-auto px-3 pb-4 md:px-6 md:pt-6 lg:pt-14 lg:pb-16"
      id={id}
      css={[
        {
          backgroundColor: haveWave && bgColor ? bgColor : '',
        },
      ]}
    >
      <Banner data={data} />
      <ProductGroups
        products={data.products}
        group={data.group}
        showId={showId}
      />
    </div>
  )
}

export default CollectionSection
