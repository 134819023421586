import React, { useEffect, useMemo, useState } from 'react'
import _, { includes, compact, map, cloneDeep, get } from 'lodash'
import tw from 'twin.macro'
import { Modal, Button, Icon } from 'semantic-ui-react'
import { useAtom, useAtomValue } from 'jotai'
import { modalAddonsVisibleAtom } from '../../../states/modal.state'
import { currencyFormatter } from '../../../utils/utils'
import ImageZoom from 'react-medium-image-zoom'
import {
  selectedProductAtom,
  selectedVariantSkuAtom,
} from '../../../states/selectedProduct'
import useOrderItems, {
  filterProductAddonAndSets,
} from '../../../hooks/useOrderItems'
import { errorToast } from '../../../utils/toastify'
import {
  TextInputStyle,
  numberInputOnWheelPreventChange,
} from '../../CartPage/CartItem'
import { CUSTOMIZABLE_PANNA_COTTA_SKU } from '../../../utils/constants'
import { addonSets } from '../../../utils/addonSets'

const AddonsModal = () => {
  const [visible, setVisible] = useAtom(modalAddonsVisibleAtom)
  const { addToCartAfterAddons } = useOrderItems()

  // xử lý dữ liệu liên quan product đang chọn
  const selectedProduct = useAtomValue(selectedProductAtom)
  const selectedVariantSku = useAtomValue(selectedVariantSkuAtom)
  const [currentVariant, setCurrentVariant] = useState({})
  useEffect(() => {
    const getSelectedVariant = () => {
      if (!get(selectedProduct, 'variants.length')) {
        return {}
      }
      const matchProductVariant = cloneDeep(
        selectedProduct.variants.find(
          (variant) => variant.sku === selectedVariantSku
        ) || {}
      )
      if (matchProductVariant.sku !== CUSTOMIZABLE_PANNA_COTTA_SKU) {
        return matchProductVariant
      }
      // variant bắt buộc có với panna tự chọn
      matchProductVariant.variants = matchProductVariant.variants || []
      if (
        !_.includes(_.map(matchProductVariant.variants, 'type'), 'PANNA_COTTA')
      ) {
        matchProductVariant.variants.push({
          type: 'PANNA_COTTA',
        })
      }
      return matchProductVariant
    }
    const selectedVariant = getSelectedVariant() || {}
    setCurrentVariant({ ...selectedVariant }) // để reset lại addons sau khi thoát modal
  }, [selectedProduct, selectedVariantSku])

  // handle functions
  const handleSelectAddon = ({ addon }) => {
    const _addon = { ...addon }
    let newProductVariant = { ...currentVariant }
    newProductVariant.variants = newProductVariant.variants || []
    const variantSkus = newProductVariant.variants.map((variant) => variant.sku)

    if (!includes(variantSkus, addon.sku)) {
      newProductVariant.variants.push(_addon)
    }

    newProductVariant.variants = compact(
      map(newProductVariant.variants, (variant) => {
        if (variant.sku !== addon.sku) {
          return variant
        }
        // checkbox type\
        // todo: refactor
        if (
          addon.maxQuantity === 1 &&
          variant.quantity &&
          variant.quantity >= 1
        ) {
          return null
        } else {
          variant.quantity = (variant.quantity || 0) + 1
        }

        // common info
        variant.image = null
        variant.originalPrice = addon.normalPrice || addon.salePrice // ngược lại với soldPrice
        variant.soldPrice = addon.salePrice || addon.normalPrice
        return variant
      })
    )
    setCurrentVariant(newProductVariant)
  }
  const handleChangeAddonOptionQuantity = ({
    addonItem,
    optionItem,
    newQuantity,
  }) => {
    let newProductVariant = { ...currentVariant }
    newProductVariant.variants = newProductVariant.variants || []
    newProductVariant.variants = map(newProductVariant.variants, (variant) => {
      if (variant.sku !== addonItem.sku) {
        return variant
      }
      variant.options = variant.options || []
      const optionSkus = map(variant.options, 'sku')
      // Nếu chưa có option thì thêm mới
      if (!includes(optionSkus, optionItem.sku)) {
        variant.options.push({
          ...optionItem,
          iconImg: null,
          quantity: newQuantity,
        })
      } else {
        // nếu đã có option thì update quantity mới
        variant.options = map(variant.options, (option) => {
          if (option.sku !== optionItem.sku) {
            return option
          }
          option.quantity = newQuantity
          return option
        })
      }
      // update quantity cho variant chính để validate số lượng tổng trong 1 set
      variant.quantity = _.sumBy(variant.options, 'quantity')
      return variant
    })
    setCurrentVariant(newProductVariant)
  }
  const handleConfirmModal = () => {
    if (!validatePannaAddons({ productVariant: currentVariant })) {
      errorToast('Vui lòng chọn đúng số lượng 5 cốc ạ')
      return
    }
    addToCartAfterAddons({
      product: selectedProduct,
      selectedVariant: currentVariant,
      noteContent: `Addon Modal Add: ${currentVariant.sku} of ${selectedProduct.description}`,
    })
    setCurrentVariant({})
  }
  const handleCloseModal = () => {
    setVisible(false)
    // setCurrentVariant({})
    // setSelectedProductId(null)
    // setSelectedVariantSku(null)
  }

  const adjustedAddonSets = useMemo(() => {
    return (
      filterProductAddonAndSets({
        product: selectedProduct || {},
        productVariant: currentVariant,
        sets: addonSets,
        showType: 'set',
      }) || []
    )
  }, [currentVariant, selectedProduct])

  return (
    <Modal
      open={visible}
      // onClose={handleCloseModal}
      tw="w-11/12 sm:w-1/2 lg:w-2/3 max-w-5xl font-savor-cake-primary-lexend"
    >
      <Modal.Content>
        <div tw="text-2xl font-semibold text-center text-savor-cake-primary-green-avocado-0">
          Chọn thêm addon bánh
        </div>
        {adjustedAddonSets.map(
          ({ setName, addons, note, checkShowNote = null }) => (
            <div key={setName}>
              <div tw="text-lg font-semibold mt-4">{setName}</div>
              {!checkShowNote || checkShowNote(currentVariant) ? (
                <p tw="text-sm font-light mt-1 text-red-400">{note}</p>
              ) : null}
              <Addons
                currentVariant={currentVariant}
                selectedProduct={selectedProduct}
                addons={addons}
                handleSelectAddon={handleSelectAddon}
                handleChangeAddonOptionQuantity={
                  handleChangeAddonOptionQuantity
                }
              />
            </div>
          )
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="grey"
          variant="outline"
          type="button"
          onClick={handleCloseModal}
        >
          Quay lại
        </Button>
        <Button
          color="green"
          variant="outline"
          type="button"
          onClick={handleConfirmModal}
        >
          Tiếp tục
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AddonsModal

const Addons = ({
  addons,
  currentVariant,
  selectedProduct,
  handleSelectAddon,
  handleChangeAddonOptionQuantity,
}) => {
  const matchAddons = useMemo(() => {
    return (
      filterProductAddonAndSets({
        product: selectedProduct || {},
        productVariant: currentVariant,
        addons,
        showType: 'addon',
      }) || []
    )
  }, [currentVariant, addons, selectedProduct])

  return (
    <>
      {matchAddons.map((addon) => {
        currentVariant.variants = currentVariant.variants || []
        const matchAddonVariant =
          currentVariant?.variants?.find(
            (variant) => variant.sku === addon.sku
          ) || {}

        return (
          <>
            {addon.maxQuantity === 1 ? (
              <AddonItemCheckbox
                addon={addon}
                handleSelectAddon={handleSelectAddon}
                matchAddonVariant={matchAddonVariant}
              />
            ) : null}
            {addon.options ? (
              <AddonItemOptions
                addon={addon}
                matchAddonVariant={matchAddonVariant}
                handleSelectAddon={handleSelectAddon}
                handleChangeAddonOptionQuantity={
                  handleChangeAddonOptionQuantity
                }
              />
            ) : null}
          </>
        )
      })}
    </>
  )
}

const AddonItemCheckbox = ({ addon, handleSelectAddon, matchAddonVariant }) => {
  console.log('🚀 ~ AddonItemCheckbox ~ addon:', addon)
  return (
    <div
      key={addon.description}
      tw="mt-2 flex gap-1 justify-start items-center w-full"
    >
      <ImageZoom>
        <div tw="w-8 md:w-12">{addon.image}</div>
      </ImageZoom>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        tw="flex justify-start gap-1 cursor-pointer w-[fit-content]"
        onClick={() => handleSelectAddon({ addon })}
        role="button"
        tabIndex={0}
      >
        <Icon
          tw="m-0!"
          color={matchAddonVariant.quantity > 0 ? 'green' : 'grey'}
          name={
            matchAddonVariant.quantity === 1
              ? 'checkmark box'
              : 'square outline'
          }
        />
        <div>
          {addon.description} - {currencyFormatter.format(addon.salePrice)}
        </div>
      </div>
    </div>
  )
}

const AddonItemOptions = ({
  addon,
  matchAddonVariant,
  handleChangeAddonOptionQuantity,
}) => {
  const matchOptionsMap = _.reduce(
    matchAddonVariant.options || [],
    (result, option) => {
      result[option.sku] = option
      return result
    },
    {}
  )
  const options = _.map(addon.options, (option) => {
    const matchOption = matchOptionsMap[option.sku]
    if (!matchOption) {
      return option
    }
    return {
      ...option,
      ...matchOption,
      iconImg: option.iconImg,
    }
  })
  return (
    <div
      key={addon.description}
      tw="mt-2 flex gap-1 justify-start items-center w-full"
    >
      <div tw="overflow-y-auto h-auto w-2/3 md:w-1/2 lg:w-2/5 font-savor-cake-secondary-quicksand">
        {!_.isEmpty(options)
          ? _.map(options, (optionItem, index) => (
              <div
                tw="border-savor-cake-primary-green-avocado-0 border-none border border-b-1 h-10 w-full flex justify-start items-center gap-1 md:gap-2 lg:gap-4"
                key={index}
              >
                <div tw="w-full font-normal text-right">{optionItem.name}</div>
                <div tw="w-8">{optionItem.iconImg}</div>
                <input
                  className="item-quantity-input"
                  min={0}
                  css={[
                    TextInputStyle,
                    tw`w-20 border! m-0! font-medium disabled:bg-disable-grey-500 rounded`,
                    optionItem.quantity > 0
                      ? tw`bg-savor-cake-secondary-green-matcha-0!`
                      : tw`bg-white`,
                  ]}
                  value={optionItem.quantity}
                  type="number"
                  onChange={(e) => {
                    handleChangeAddonOptionQuantity({
                      addonItem: addon,
                      optionItem,
                      newQuantity: parseInt(e.target.value || 0),
                    })
                  }}
                  onWheel={numberInputOnWheelPreventChange}
                  placeholder="0-5"
                />
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

function validatePannaAddons({ productVariant }) {
  if (productVariant.sku !== CUSTOMIZABLE_PANNA_COTTA_SKU) {
    return true
  }
  const pannaVariant =
    _.find(productVariant.variants, { type: 'PANNA_COTTA' }) || {}
  return pannaVariant.quantity === 5
}
