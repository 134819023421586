import React, { forwardRef } from 'react'
import { styled } from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'

import { buttonVariants } from '../../utils/motion'
import { Heading2, Description, SectionContainer, InboxText } from './Base'
import { containerStyle } from '../Base'
import '../../styles/staticImageInlineStyles.css'
import { Link } from 'react-scroll'

const shipImages = [
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/addons/nhan_xoai_dua2.jpeg"
        alt="nhân xoài dứa"
        placeholder="blurred"
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/addons/nhan_xoai_dua3.jpeg"
        alt="nhân xoài dứa"
        placeholder="blurred"
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/addons/nhan_xoai_dua1.jpeg"
        alt="nhân xoài dứa"
        placeholder="blurred"
      />
    ),
  },
]

const OrderBtn = styled.button`
  padding: 6px 0;
  width: 40%;
  min-width: 260px;
  margin: 60px auto 30px;
  background: linear-gradient(to right, #fbd745, #fcae16);
  border-radius: 10rem;
  display: block;
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    margin: 4px 4px;
    border: 1px solid #f3ad22;
    border-radius: 10rem;
  }
`

const AddonSection = forwardRef((props, ref) => {
  return (
    <SectionContainer background="#FEFBF0">
      {/* main */}
      <section tw="relative z-10 h-full pt-8 pb-2" ref={ref}>
        <div css={containerStyle}>
          <Heading2>Thêm nhân hoa quả</Heading2>
          <div
            tw="pt-6 flex flex-col md:flex-row justify-center items-center
            gap-2 lg:gap-4 w-full
            z-10 max-w-5xl bg-transparent mx-auto
            "
          >
            {shipImages.map((item, index) => (
              <div key={index} tw="w-5/6 sm:w-2/3 md:w-1/3 mx-auto">
                {item.image}
              </div>
            ))}
          </div>
          <Description style={{ marginTop: '2rem', marginBottom: '0.5rem' }}>
            Nếu trước đây Savor chỉ có nhân xoài tươi cho cả nhà lựa chọn, thì
            hiện tại Savor đã bổ sung thêm các loại nhân hoa quả khác, đặc biệt
            phải kể: NHÂN XOÀI DỨA - Sự kết hợp hoàn hảo giữa vị chua của dứa
            cân bằng với vị ngọt của xoài cùng hương thơm tươi mát, dịu nhẹ
            <br />
            ~~~~~~~~~~~~~~~~~~~~~
            <br />
            Chỉ cần thêm một chút phí nho nhỏ tùy theo size bánh
            <br />
            Size mini: 10k/60g
            <br />
            Size nhỏ: 25k/150g
            <br />
            Size vừa: 40k/240g
            <br />
            ~~~~~~~~~~~~~~~~~~~~~
            <br />
            Lưu ý: Các mẫu bánh Mousse không được áp dụng thêm nhân hoa quả, cả
            nhà nhắn Savor để được tư vấn các mẫu bánh nha
          </Description>
          <motion.div variants={buttonVariants} whileHover="hover">
            <Link smooth={true} to="scake-fast-1h" href="#scake-fast-1h">
              <OrderBtn>
                <InboxText>Đặt bánh ngay</InboxText>
              </OrderBtn>
            </Link>
          </motion.div>
        </div>
      </section>
    </SectionContainer>
  )
})
export default AddonSection
