import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const xoaiDuaFillingAddonProps = {
  type: 'CAKE_FILLING_ADDON',
  maxQuantity: 1,
  image: (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/addons/nhan_xoai_dua2.jpeg"
      alt="nhân xoài dừa"
      placeholder="blurred"
    />
  ),
}
export const addonSets = [
  {
    setName: 'Thêm nhân',
    addons: [
      {
        ...xoaiDuaFillingAddonProps,
        sku: 'V8607',
        cakeSizeName: 'mini',
        salePrice: 10000,
        description: 'Nhân xoài dứa (size mini)',
      },
      {
        ...xoaiDuaFillingAddonProps,
        sku: 'V8608',
        cakeSizeName: 'nhỏ',
        salePrice: 25000,
        description: 'Nhân xoài dứa (size nhỏ)',
      },
      {
        ...xoaiDuaFillingAddonProps,
        sku: 'V8609',
        cakeSizeName: 'vừa',
        salePrice: 40000,
        description: 'Nhân xoài dứa (size vừa)',
      },
    ],
  },
  {
    setName: 'Thêm bánh su kem',
    note: 'Quý khách lưu ý: Các mẫu BÁNH 1H nếu thêm su kem thì thời gian xử lý sẽ TĂNG THÀNH 2H ạ',
    checkShowNote: (productVariant) => productVariant.prepareTime < 1.5,
    addons: [
      {
        image: (
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/products/2024/upsize-banh-su-kem.jpg"
            alt="upsize combo bánh su kem"
            placeholder="blurred"
          />
        ),
        type: 'CHOUX_CREAM',
        maxQuantity: 1,
        sku: 'V8846',
        cakeSizeName: 'all',
        salePrice: 90000, // bao gồm 20k của đế (đế xử lý auto ở server, ko show khách)
        description: 'Upsize combo bánh su kem hoa quả tươi (nho, xoài)',
      },
      {
        image: (
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/images/products/combos/V9108-V9112-20240815.jpg"
            alt="upsize combo bánh su kem mix chà bông"
            placeholder="blurred"
          />
        ),
        type: 'CHOUX_CREAM',
        maxQuantity: 1,
        sku: 'V9112',
        cakeSizeName: 'all',
        salePrice: 90000, // bao gồm 20k của đế (đế xử lý auto ở server, ko show khách)
        description: 'Upsize combo bánh su kem mix chà bông',
      },
    ],
  },
  {
    setName: 'Vị panna cotta tự chọn',
    isPannaChoice: true,
    note: 'Quý khách vui lòng chọn tổng số lượng là 5, số vị tùy ý',
    addons: [
      {
        type: 'PANNA_COTTA',
        maxQuantity: 5,
        options: [
          {
            sku: 'V8589',
            name: 'Xoài',
            iconImg: (
              <StaticImage
                src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/mango-cut.png"
                alt="mango"
              />
            ),
          },
          {
            sku: 'V8590',
            name: 'Dâu',
            iconImg: (
              <StaticImage
                src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/strawberry.png"
                alt="strawberry"
              />
            ),
          },
          {
            sku: 'V8591',
            name: 'Việt quất',
            iconImg: (
              <StaticImage
                src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/blueberry.png"
                alt="blueberry"
              />
            ),
          },
          {
            sku: 'V8592',
            name: 'Chanh leo',
            iconImg: (
              <StaticImage
                src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/passion-fruit.png"
                alt="chanh leo"
              />
            ),
          },
          {
            sku: 'V8593',
            name: 'Kiwi',
            iconImg: (
              <StaticImage
                src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/kiwi.png"
                alt="kiwi"
              />
            ),
          },
        ],
      },
    ],
  },
]
