import React from 'react'
import Slider from 'react-slick'
import { useAtomValue } from 'jotai'
import { map } from 'lodash'

import * as styles from './ProductSlider.module.scss'
import ProductCard from './ProductCard'
import {
  getSaleSortedProducts,
  handleRejectInvalidShowUpProducts,
} from '../../utils/utils'
import { sliderSettings } from '../../utils/settings'
import { saleMapAtom } from '../../states/saleData'

const styleVariantMap = {
  chocolate: {
    slider: 'greenSlider',
    colorVariantCode: '',
  },
  mousse: {
    slider: 'slider',
    colorVariantCode: 'green',
  },
  'fresh-fruit': {
    slider: 'orangeSlider',
    colorVariantCode: 'orange',
  },
  'tet-cake-candy': {
    slider: 'greenSlider',
    colorVariantCode: 'green',
  },
}
export default function CollectionSLider({ products, group }) {
  const newProducts = handleRejectInvalidShowUpProducts(products)
  const settings = sliderSettings
  const saleMap = useAtomValue(saleMapAtom)

  const finalSortedProducts = getSaleSortedProducts(newProducts, saleMap)

  const styleVariant = styleVariantMap[group]

  return (
    <div id="bsn_collection_show_type">
      <Slider {...settings} className={styles[styleVariant.slider]}>
        {map(finalSortedProducts, (product) => (
          <ProductCard
            product={product}
            key={product.description}
            colorVariantCode={styleVariant.colorVariantCode}
          />
        ))}
      </Slider>
    </div>
  )
}
