import React from 'react'
import 'twin.macro'
import { siteUrl } from '../constants'

const event1 = () => {
  return (
    <div tw="">
      <header>
        <div tw="text-left">
          <h6>
            <a href={`${siteUrl}/hoat-dong-xa-hoi`} rel="category tag">
              Hoạt động xã hội
            </a>
          </h6>
          <h1 tw="mt-1 mb-6">
            Bước Đến Ánh Sáng: Chặng Đường Phấn Đấu của Học Sinh Mồ Côi tại Văn
            Lăng
          </h1>
          <div></div>
        </div>
      </header>

      <div class="entry-content single-page">
        <p tw="text-justify">
          <em>
            <span>
              Trong cuộc sống, chúng ta thường phải đối mặt với rất nhiều khó
              khăn. Tuy nhiên, luôn có những người dù phải đối diện với những
              thử thách, vẫn không bao giờ từ bỏ hy vọng. Đó là câu chuyện đặc
              biệt về Em Hoàng Thị Hương, một học sinh mồ côi tại trường PTDTBT
              Tiểu học số 1 Văn Lăng – huyện Đồng Hỷ – tỉnh Thái Nguyên. Hành
              trình của cô bé đã chứng minh rằng, dù cuộc sống này có nhiều gian
              khó cũng không thể làm chùn bước được khát vọng đến với tri thức.
            </span>
          </em>
        </p>
        <p>
          <strong>
            <span>
              Báo Công Thương quan tâm về chương trình “Thắp Sáng Tương Lai”.{' '}
            </span>
          </strong>
          <span>
            Xem chi tiết bài viết:{' '}
            <span>
              <em>
                <a href="https://tapchicongthuong.vn/bai-viet/thap-sang-tuong-lai-cho-cac-em-nho-xa-vung-cao-van-lang-tinh-thai-nguyen-110611.htm">
                  tại đây
                </a>
              </em>
            </span>
          </span>
        </p>
        <p>
          <strong>
            <span>
              Báo Doanh nghiệp &amp; Pháp luật đưa tin về chương trình.{' '}
            </span>
          </strong>
          <span>
            Xem chi tiết bài viết:{' '}
            <em>
              <span>
                <a href="https://doanhnhan.vn/chuong-trinh-thap-sang-tuong-lai-so-2-tlc-viet-nam-se-chia-anh-sang-va-tri-thuc-voi-cac-ban-nho-vung-cao-van-lang-tinh-thai-nguyen-63264.html">
                  tại đây
                </a>
              </span>
            </em>
          </span>
        </p>
        <figure
          id="attachment_11568"
          aria-describedby="caption-attachment-11568"
          class="wp-caption aligncenter"
        >
          <img
            data-lazyloaded="1"
            src="https://tlclighting.com.vn/wp-content/uploads/2023/09/1111.jpg"
            class="wp-image-11568 size-full entered litespeed-loaded"
            data-src="https://tlclighting.com.vn/wp-content/uploads/2023/09/1111.jpg"
            alt="Thắp sáng tương lai 3"
            width="600"
            height="400"
            data-srcset="https://tlclighting.com.vn/wp-content/uploads/2023/09/1111.jpg 600w, https://tlclighting.com.vn/wp-content/uploads/2023/09/1111-510x340.jpg 510w, https://tlclighting.com.vn/wp-content/uploads/2023/09/1111-300x200.jpg 300w, https://tlclighting.com.vn/wp-content/uploads/2023/09/1111-31x21.jpg 31w"
            data-sizes="(max-width: 600px) 100vw, 600px"
            data-ll-status="loaded"
            sizes="(max-width: 600px) 100vw, 600px"
            srcset="https://tlclighting.com.vn/wp-content/uploads/2023/09/1111.jpg 600w, https://tlclighting.com.vn/wp-content/uploads/2023/09/1111-510x340.jpg 510w, https://tlclighting.com.vn/wp-content/uploads/2023/09/1111-300x200.jpg 300w, https://tlclighting.com.vn/wp-content/uploads/2023/09/1111-31x21.jpg 31w"
          />
          <figcaption id="caption-attachment-11568" class="wp-caption-text">
            Điểm trường PTDTBT Tiểu học Văn Lăng 1
          </figcaption>
        </figure>
        <p tw="text-justify">
          <span>
            Sau một biến cố lớn, em Hoàng Thị Hương đã mất đi cả bố lẫn mẹ. Cuộc
            sống của Hương và anh trai vô cùng khó khăn. Nhưng trong bóng tối
            của sự mất mát, em và anh trai may mắn tìm thấy ánh sáng của hy vọng
            dưới sự che chở của người bác ruột. Tình thương từ người thân là chỗ
            dựa lớn nhất cho hai em nhỏ vượt qua những mất mát, thiếu thốn.
          </span>
        </p>
        <p tw="text-justify">
          <span>
            Bên cạnh sự chăm sóc từ người bác ruột, Hương còn được các thầy cô
            tại Trường PTDTBT Tiểu học Văn Lăng 1 đặc biệt quan tâm và che chở.
            Em học tập và sinh hoạt nội trú tại trường. Trường học giờ đây trở
            thành ngôi nhà thứ 2 của em, các thầy cô trở thành người cha người
            mẹ hiền bù đắp cho em vơi đi sự mất mát và vết thương lớn. Mặc dù
            thiếu thốn về nền tảng tinh thần nhưng với sự nỗ lực vượt lên hoàn
            cảnh, Hương hiện đang là học sinh lớp 5A đã liên tục đạt danh hiệu
            học sinh xuất sắc 4 năm liên tiếp, luôn dẫn đầu lớp học và đạt được
            những thành tựu đáng tự hào.&nbsp;
          </span>
        </p>
        <figure
          id="attachment_11569"
          aria-describedby="caption-attachment-11569"
          class="wp-caption aligncenter"
        >
          <img
            data-lazyloaded="1"
            src="https://tlclighting.com.vn/wp-content/uploads/2023/09/111.jpg"
            class="wp-image-11569 size-full entered litespeed-loaded"
            data-src="https://tlclighting.com.vn/wp-content/uploads/2023/09/111.jpg"
            alt="Thắp sáng tương lai 2"
            width="600"
            height="400"
            data-srcset="https://tlclighting.com.vn/wp-content/uploads/2023/09/111.jpg 600w, https://tlclighting.com.vn/wp-content/uploads/2023/09/111-510x340.jpg 510w, https://tlclighting.com.vn/wp-content/uploads/2023/09/111-300x200.jpg 300w, https://tlclighting.com.vn/wp-content/uploads/2023/09/111-31x21.jpg 31w"
            data-sizes="(max-width: 600px) 100vw, 600px"
            data-ll-status="loaded"
            sizes="(max-width: 600px) 100vw, 600px"
            srcset="https://tlclighting.com.vn/wp-content/uploads/2023/09/111.jpg 600w, https://tlclighting.com.vn/wp-content/uploads/2023/09/111-510x340.jpg 510w, https://tlclighting.com.vn/wp-content/uploads/2023/09/111-300x200.jpg 300w, https://tlclighting.com.vn/wp-content/uploads/2023/09/111-31x21.jpg 31w"
          />
          <figcaption id="caption-attachment-11569" class="wp-caption-text">
            Em Hoàng Thị Hương và các bạn nhỏ trong giờ học tập
          </figcaption>
        </figure>
        <p tw="text-justify">
          <span>
            <span tw="font-normal">
              Sau khi biết đến hoàn cảnh đặc biệt của em Hoàng Thị Hương, và
              những khó khăn của Trường PTDTBT Tiểu học số 1 Văn Lăng – một
              trong những điểm trường xa xôi nhất tại vùng núi Thái Nguyên, Tập
              đoàn TLC Việt Nam đã quyết định khởi động{' '}
            </span>
            <b>Chuyến xe “Thắp sáng tương lai”</b>
            <span tw="font-normal"> đến đây. </span>
          </span>
          <strong>
            <span>
              <a href="https://tlclighting.com.vn/">TLC LIGHTING</a>
            </span>
          </strong>
          <span tw="font-normal">
            {' '}
            thấu hiểu rằng giáo dục và tình thương là những khoản đầu tư quý báu
            nhất vào tương lai của một quốc gia và cộng đồng. Chuyến xe “Thắp
            sáng tương lai” là sự hiện thực hóa của sứ mệnh này, đem lại niềm hy
            vọng cho các em nhỏ ở các điểm trường vùng cao còn nhiều thiếu thốn.
          </span>
        </p>
      </div>
    </div>
  )
}

export default event1
