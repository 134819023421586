import React, { useState, useEffect } from 'react'
import 'twin.macro'

const CountdownClock = ({ targetDatetime }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDatetime) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        ngày: Math.floor(difference / (1000 * 60 * 60 * 24)),
        giờ: Math.floor((difference / (1000 * 60 * 60)) % 24),
        phút: Math.floor((difference / 1000 / 60) % 60),
        giây: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    // if (!timeLeft[interval]) {
    //   return
    // }

    timerComponents.push({
      interval,
      value: timeLeft[interval],
    })
  })

  return (
    <div tw="flex justify-center items-center flex-col w-full pt-10 lg:pt-12 gap-3 font-savor-cake-primary-lexend">
      <div tw="text-center text-savor-cakes-brown-500">
        <p tw="mb-2 text-xl md:text-2xl lg:text-3xl">
          Cùng đón chờ{' '}
          <span tw="text-savor-cake-primary-green-avocado-0 font-bold lg:font-semibold text-3xl md:text-4xl lg:text-5xl">
            Savor Cake
          </span>{' '}
          <br tw="lg:hidden" />
          xuất hiện trên{' '}
          <span tw="lg:block xl:inline text-shark-tank-blue1 font-bold lg:font-semibold text-3xl md:text-4xl lg:text-5xl">
            Shark Tank
          </span>{' '}
        </p>
        <p tw="text-lg md:text-xl font-normal px-5 md:px-10 lg:px-20">
          vào lúc 20h30 Thứ 2 ngày 15/01/2024 trên VTV3 nhé!
        </p>
      </div>
      {timerComponents.length ? (
        <div tw="flex justify-center items-center w-full space-x-2 text-shark-tank-blue1">
          {timerComponents.map(({ interval, value }) => {
            const checkHide = () => {
              if (['giờ', 'ngày'].includes(interval) && !value) {
                return true
              }
              return false
            }
            return (
              <>
                {checkHide() ? null : (
                  <>
                    <div tw="text-3xl md:text-4xl lg:text-5xl">
                      {value}
                      <span tw="text-lg">{interval}</span>
                    </div>
                    {['giờ', 'phút'].includes(interval) ? (
                      <div tw="text-lg"> : </div>
                    ) : null}
                  </>
                )}
                {interval === 'ngày' && value ? (
                  <div tw="text-lg"> - </div>
                ) : null}
              </>
            )
          })}
        </div>
      ) : (
        <span tw="text-shark-tank-blue1 text-xl lg:text-3xl text-center">
          🎉 Đến giờ rồi, cùng mở VTV3 xem và ủng hộ Savor nha 🎉
        </span>
      )}
    </div>
  )
}

export default CountdownClock
