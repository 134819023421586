import React, { useEffect, useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Icon } from 'semantic-ui-react'
import useMediaQuery from '../../hooks/useMediaQuery'
import { styled } from 'twin.macro'
import { map } from 'lodash'

const TopMenuContainer = styled.div`
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px 0;
  display: inline-grid;
  grid-template-columns: repeat(5, 1fr);
  z-index: 9999;
  align-items: center;
  @media screen and (min-width: 1024px) {
    position: relative;
  }
  .topMenu__logo {
    width: 65px;
    grid-column: 3 /4;
    margin: 0 auto;
    @media only screen and (min-width: 1024px) {
      width: 75px;
      grid-column: 1/ 2;
      margin: unset;
    }
    grid-row: 1/2;
  }
  .topMenu__list {
    font-size: 22px;
    text-align: center;
    @media only screen and (min-width: 1024px) {
      gap: 15px;
      grid-column: 2/ 6;
      font-size: 1.25rem !important;
    }
    @media only screen and (min-width: 1280px) {
      font-size: 1.5rem !important;
    }
    @media only screen and (min-width: 1536px) {
      font-size: 1.75rem !important;
    }
    align-items: center;
    justify-content: space-between;
    display: flex;
    list-style-type: none;
    color: #fff;
    font-family: 'Lexend Deca', sans-serif !important;
    font-weight: 600;
    line-height: 1;
    a {
      padding: 10px 5px;
      flex-basis: 25%;
      cursor: pointer;
      position: relative;
      transform-origin: 50% 50%;
      color: #fff;
      &::after {
        transition: 0.2s linear;
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        background-color: #fff;
      }
      &:hover::after {
        transform: scaleX(1);
      }
    }
    &.topMenu__list__mobile {
      background-color: #fff;
      position: absolute;
      border-radius: 10px;
      top: 50%;
      flex-direction: column;
      color: #000;
      padding: 0;
      overflow: hidden;
      a {
        color: #000;
        padding: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 400;
        margin: 0;
        &:not(:first-child) {
          border-top: 0.2px solid #ddd;
        }
        &::after {
          display: none;
        }
      }
    }
  }
`

const TopMenu = ({ refMap }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  // clickOut Menu-Mobile
  const [showMenu, setShowMenu] = useState(false)
  const dropdownRef = useRef(null)
  useEffect(() => {
    function handleClickOut(e) {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowMenu(false)
      }
    }
    document.addEventListener('click', handleClickOut)
    return () => {
      document.removeEventListener('click', handleClickOut)
    }
  }, [])

  const menuItems = [
    {
      name: 'introduce',
      path: '',
      ref: refMap.featureRef,
      label: 'Giới thiệu',
    },
    {
      name: 'list_products',
      path: '',
      ref: refMap.productsRef,
      label: 'Danh mục',
    },
    {
      name: 'policy',
      path: '',
      ref: refMap.policyRef,
      label: 'Chính sách',
    },
    {
      name: 'locations',
      path: '',
      ref: refMap.locationRef,
      label: 'Cửa hàng',
    },
    {
      name: 'feedback',
      path: '',
      ref: refMap.feedbackRef,
      label: 'Feedback',
    },
    // {
    //   name: 'order',
    //   path: '',
    //   externalLink: 'https://m.me/savor.vn',
    //   label: 'Tư vấn',
    // },
    {
      name: 'contact',
      path: '',
      ref: refMap.footerRef,
      label: 'Liên hệ',
    },
  ]

  function handleClick(item) {
    setShowMenu(false)
    if (item.ref && item.ref.current) {
      item.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    } else if (item.externalLink) {
      window.open(item.externalLink, '_blank')
    }
  }

  return (
    <TopMenuContainer
      tw="px-6 md:px-14 lg:px-32 lg:pr-40 2xl:px-44 2xl:pl-40 lg:pt-10 2xl:pt-12"
      ref={dropdownRef}
    >
      {!isDesktop && (
        <Icon
          tw="text-savor-cake-primary-green-avocado-100!"
          name={!showMenu ? 'bars' : 'close'}
          inverted
          style={{ position: 'relative' }}
          size="big"
          onClick={() => setShowMenu(!showMenu)}
        />
      )}
      {showMenu || isDesktop ? (
        <div
          className={`topMenu__list ${!isDesktop && 'topMenu__list__mobile'}`}
        >
          {map(menuItems, (item) => (
            <a
              href={item.ref ? item.ref.id : null}
              key={item.name}
              name={item.name}
              onClick={() => handleClick(item)}
              tw="py-4"
            >
              <span tw="text-savor-cakes-brown-500 lg:text-savor-cake-primary-green-avocado-100">
                {item.label}
              </span>
            </a>
          ))}
        </div>
      ) : (
        ''
      )}
      {isDesktop ? (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/logos/savor-cakes-logo-green.png"
          alt="savor-cakes-logo"
          className="topMenu__logo"
          width={150}
        ></StaticImage>
      ) : (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/logos/savor-cakes-logo-green.png"
          alt="savor-cakes-log"
          className="topMenu__logo"
        ></StaticImage>
      )}
      {/* {isDesktop ? (
        <StaticImage
          src={`https://s3.kstorage.vn/savor-web/src/assets/images/logos/savor-cake-logo-pink.png`}
          alt="savor-cakes-logo"
          className="topMenu__logo"
          width={120}
        />
      ) : (
        <StaticImage
          src={`https://s3.kstorage.vn/savor-web/src/assets/images/logos/savor-cake-logo-pink.png`}
          alt="savor-cakes-logo"
          className="topMenu__logo"
        />
      )} */}
      {/* {isDesktop ? (
        <StaticImage
          src={`https://s3.kstorage.vn/savor-web/src/assets/images/logos/savor-cake-logo-blue.png`}
          alt="savor-cakes-logo"
          className="topMenu__logo"
          width={120}
        />
      ) : (
        <StaticImage
          src={`https://s3.kstorage.vn/savor-web/src/assets/images/logos/savor-cake-logo-blue.png`}
          alt="savor-cakes-logo"
          className="topMenu__logo"
        />
      )} */}
    </TopMenuContainer>
  )
}
export default TopMenu
