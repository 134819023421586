import React, { useEffect } from 'react'
import tw from 'twin.macro'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'

const Button = ({ toTop, side }) => {
  const [showUp, setShowUp] = React.useState(false)

  // Add a scroll event listener
  useEffect(() => {
    const checkScrollTop = function () {
      const trigger = 200
      // hiện nút scroll down nếu scroll xuống hơn khoảng chỉ định
      if (
        !showUp &&
        (document.body.scrollTop >= trigger ||
          document.documentElement.scrollTop >= trigger ||
          window.scrollY >= trigger)
      ) {
        setShowUp(true)
      } else {
        setShowUp(false)
      }
    }
    window.addEventListener('scroll', checkScrollTop)
    return () => window.removeEventListener('scroll', checkScrollTop)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // add a click event listener
  const handleClick = (e) => {
    e.preventDefault()
    window.scrollTo({
      top: toTop ? 0 : document.body.scrollHeight,
      behavior: 'smooth',
    })
  }

  return (
    <button
      css={[
        tw`z-50 w-8 h-8 border border-solid border-white bg-savor-cake-primary-green-avocado-0 text-white flex flex-col items-center justify-center cursor-pointer`,
        toTop && !showUp ? tw`invisible` : '',
        side === 'left' ? tw`rounded-l` : tw`rounded-r`,
      ]}
      onClick={handleClick}
    >
      {toTop ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
    </button>
  )
}

const ScrollButtons = (props) => {
  return (
    <div
      tw="fixed left-0 bottom-36 md:bottom-44 flex flex-col items-end gap-2 z-50"
      {...props}
    >
      <Button toTop />
      <Button />
    </div>
  )
}

export default ScrollButtons
