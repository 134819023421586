import React, { forwardRef } from 'react'
import 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'

import { buttonVariants } from '../../utils/motion'
import { cakePolicyPostLink } from '../../utils/constants'
import { Heading2, Description, Line, SectionContainer } from './Base'
import { containerStyle } from '../Base'
// import useMediaQuery from '../../hooks/useMediaQuery'
import '../../styles/staticImageInlineStyles.css'

const shipImages = [
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/policy/cod.jpg"
        alt="policy cod"
        placeholder="blurred"
        // style={{ height: '100%' }}
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/policy/voucherFbPolicy.jpg"
        alt="policy discount and voucher"
        placeholder="blurred"
        // style={{ height: '100%' }}
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/policy/discountPolicy.jpg"
        // src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/policy/cash-back.jpg"
        alt="policy cash back"
        placeholder="blurred"
        // style={{ height: '100%' }}
      />
    ),
  },
]

const CakePolicySection = forwardRef((props, ref) => {
  // const isMediumScreen = useMediaQuery('(min-width: 768px)')
  return (
    <SectionContainer background="#FEFBF0">
      {/* {!isMediumScreen && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/combo-bot-wave-mobile.png"
          alt="wave"
          layout="fullWidth"
          placeholder="blurred"
        />
      )}
      {isMediumScreen && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/combo-bot-wave.png"
          alt="wave"
          layout="fullWidth"
          placeholder="blurred"
        />
      )} */}
      {/* main */}
      <section tw="relative z-10 h-full pt-8 pb-2" id="policy" ref={ref}>
        <div css={containerStyle}>
          <Heading2>Chính sách ship & bán hàng</Heading2>
          <Line center={true} />
          {dayjs().isBefore(dayjs('2024-01-01')) ? (
            <Description
              on
              style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            >
              * Áp dụng từ 01/01/2024
            </Description>
          ) : null}

          <motion.a
            variants={buttonVariants}
            whileHover="hover"
            tw="
            "
            href={cakePolicyPostLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Description
              style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            >
              Bấm để xem thêm chi tiết TẠI ĐÂY
            </Description>
          </motion.a>
          <div
            tw="pt-6 flex flex-col md:flex-row justify-center items-center
            gap-2 lg:gap-4 w-full
            z-10 max-w-5xl bg-transparent mx-auto
            "
          >
            {shipImages.map((item, index) => (
              <div key={index} tw="w-5/6 sm:w-2/3 md:w-1/3 mx-auto">
                {item.image}
              </div>
            ))}
          </div>
        </div>
      </section>
    </SectionContainer>
  )
})
export default CakePolicySection
