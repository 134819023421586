import 'twin.macro'
import * as React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-scroll'
import { StaticImage } from 'gatsby-plugin-image'
import { useAnimation, motion } from 'framer-motion'
import { revealVariants, buttonVariants } from '../../utils/motion'
import { useInView } from 'react-intersection-observer'
import useMediaQuery from '../../hooks/useMediaQuery'
import { StaticBgImageStyle, StaticImgAbsoluteStyle } from '../Base'
import TopMenu from './TopMenu'
import { SectionWrapper } from '../BanhSinhNhatPage/Base'

export default function HeroSection({ refMap }) {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
      id="scake-savor-cake-main-hero"
    >
      <HeroSectionWrapper isDesktop={isDesktop}>
        <TopMenu refMap={refMap} />

        {/* hero container */}
        <div tw="w-full mx-auto p-0 lg:pb-0 xl:pb-2 2xl:py-0 lg:px-4 xl:px-10 2xl:px-32">
          {/* main content */}
          <div
            tw="flex flex-col lg:grid lg:grid-cols-14
                mx-auto h-full mb-4"
          >
            {/* heading */}
            <div
              tw="lg:col-start-1 lg:col-span-5 mt-32 lg:mt-10 lg:pt-4 xl:mt-14 xl:pt-2
              mx-4 sm:mx-12 lg:mx-0
              lg:ml-8
            "
            >
              {/* Header */}
              <HeroHeading isDesktop={isDesktop} />

              {/* Order button on large screen */}
              <div tw="hidden lg:block lg:w-[115%]">
                <OrderButton />
              </div>
            </div>

            {/* cake image */}
            <HeroCakes isDesktop={isDesktop} />

            <div tw="lg:hidden">
              <OrderButton />
            </div>
          </div>
        </div>
      </HeroSectionWrapper>
    </motion.div>
  )
}

const Left = ({ isDesktop }) => {
  return (
    <>
      {isDesktop ? (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/topLeft.png"
          alt="background image"
          placeholder="blurred"
          layout="fullWidth"
          style={{
            ...StaticImgAbsoluteStyle,
            left: 0,
            top: 0,
            width: '18%',
          }}
        />
      ) : (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/topLeftMob.png"
          alt="background image"
          placeholder="blurred"
          layout="fullWidth"
          style={{
            ...StaticImgAbsoluteStyle,
            left: 0,
            top: 0,
            width: '30%',
          }}
        />
      )}
    </>
  )
}
const LeftMid = ({ isDesktop }) => {
  const style = {
    position: 'absolute',
    height: 'auto',
    zIndex: 0,
    overflow: 'visible',
    aspectRatio: 'auto',
    width: isDesktop ? '5%' : '6%',
  }
  return (
    <>
      {isDesktop ? null : ( // /> //   }} //     top: '55%', //     ...style, //   style={{ //   placeholder="blurred" //   alt="background image" //   src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/kiwiLeft.png" // <StaticImage
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/kiwiLeftMob.png"
          alt="background image"
          placeholder="blurred"
          style={{
            ...style,
            top: '45%',
          }}
        />
      )}
    </>
  )
}
const RightMid = ({ isDesktop }) => {
  return (
    <>
      {isDesktop ? (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/kiwiRightMob.png"
          alt="background image"
          placeholder="blurred"
          style={{
            ...StaticImgAbsoluteStyle,
            right: 0,
            top: '30%',
            width: '6%',
          }}
        />
      ) : (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/kiwiRightMob.png"
          alt="background image"
          placeholder="blurred"
          style={{
            ...StaticImgAbsoluteStyle,
            right: 0,
            top: '50%',
            width: '8%',
          }}
        />
      )}
    </>
  )
}
const TopRight = ({ isDesktop }) => {
  const style = {
    ...StaticImgAbsoluteStyle,
    width: isDesktop ? '15%' : '25%',
    right: 0,
    top: 0,
  }
  return (
    <>
      {isDesktop ? (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/topRight.png"
          alt="background image"
          placeholder="blurred"
          layout="fullWidth"
          style={style}
        />
      ) : (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/topRightMob.png"
          alt="background image"
          placeholder="blurred"
          layout="fullWidth"
          style={style}
        />
      )}
    </>
  )
}
const BotRight = ({ isDesktop }) => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/botRight.png"
      alt="background image"
      placeholder="blurred"
      layout="fullWidth"
      style={{
        ...StaticImgAbsoluteStyle,
        width: isDesktop ? '13%' : '24%',
        right: 0,
        bottom: isDesktop ? '3%' : '0',
      }}
    />
  )
}
const BotLeft = ({ isDesktop }) => {
  const style = {
    ...StaticImgAbsoluteStyle,
    width: isDesktop ? '13%' : '19%',
    left: isDesktop ? 0 : 0,
    bottom: isDesktop ? '5%' : '0',
  }
  return (
    <>
      {isDesktop ? (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/botLeft.png"
          alt="background image"
          placeholder="blurred"
          layout="fullWidth"
          style={style}
        />
      ) : (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/botLeftMob.png"
          alt="background image"
          placeholder="blurred"
          layout="fullWidth"
          style={style}
        />
      )}
    </>
  )
}

const HeroSectionWrapper = ({ children, isDesktop }) => {
  return (
    <SectionWrapper>
      {isDesktop && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/bgHero.png"
          alt="Background Image"
          style={StaticBgImageStyle}
          loading="eager"
          quality={100}
          placeholder="blurred"
          backgroundColor="#FFF8DD"
        />
      )}
      {!isDesktop && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/bgHeroMob.png"
          alt="Background Image"
          style={StaticBgImageStyle}
          loading="eager"
          quality={100}
          placeholder="blurred"
          backgroundColor="#FFF8DD"
        />
      )}
      <Left isDesktop={isDesktop} />
      <LeftMid isDesktop={isDesktop} />
      <TopRight isDesktop={isDesktop} />
      <RightMid isDesktop={isDesktop} />
      <BotRight isDesktop={isDesktop} />
      <BotLeft isDesktop={isDesktop} />
      {children}

      {/* Wave */}
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/d-wave-white-thin.png"
        // src="../../assets/images/landing-page-bsn/waves/wave-bot-hero-beige.png"
        alt=""
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    </SectionWrapper>
  )
}
const HeroHeading = ({ isDesktop }) => {
  return (
    <div tw="w-11/12 md:w-4/5 lg:w-[115%] mx-auto mb-3">
      {isDesktop ? (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/head.png"
          alt="Heading Hương vị mùa hè"
          placeholder="blurred"
        />
      ) : (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/head.png"
          alt="Heading Hương vị mùa hè"
          placeholder="blurred"
        />
      )}
    </div>
  )
}

const HeroCakes = ({ isDesktop }) => {
  return (
    <div
      tw="lg:col-span-9 lg:col-start-6 m-auto lg:mt-6 w-full lg:h-full lg:flex lg:flex-col lg:justify-end mt-4 mb-4 h-full
      "
    >
      <div tw="lg:mx-auto relative lg:ml-10 lg:pb-0 h-auto">
        {isDesktop ? (
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/cakes.png"
            alt="Ảnh bánh sinh nhật"
            placeholder="blurred"
          />
        ) : (
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/hero-section/fruit2-2024/cakes.png"
            alt="Ảnh bánh sinh nhật"
            placeholder="blurred"
          />
        )}
      </div>
    </div>
  )
}
const OrderButton = () => {
  return (
    <div tw="flex justify-center w-full">
      <motion.div variants={buttonVariants} whileHover="hover">
        <Link smooth={true} to="scake-fast-1h" href="#scake-fast-1h">
          {/* cần href ở Link (thực chất là thẻ a) để tăng điểm SEO cho tiêu chí Link are crawlable */}
          <button
            tw="row-span-1 row-start-5 w-56 h-10 lg:w-64 lg:h-14 2xl:w-80 2xl:h-18
            text-white text-lg lg:text-xl 2xl:text-2xl
              rounded-100 border-0 bg-savor-cakes-orange-1000
              m-auto
              font-savor-cake-primary-lexend font-bold
              mb-10 mt-2 sm:mt-2 lg:mt-8
              lg:col-span-6 lg:row-span-1 lg:col-start-1
              cursor-pointer
              uppercase
            "
          >
            đặt bánh ngay
          </button>
        </Link>
      </motion.div>
    </div>
  )
}
