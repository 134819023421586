import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { filter } from 'lodash'
import eventsList from './list'

export const socialEvents = [
  {
    id: 'event-1',
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/images/events/lam-banh-cho-em.jpg"
        alt="event"
        placeholder="blurred"
      />
    ),
    title: 'Chuỗi hoạt động thiện nguyện "Làm Bánh Cho Em"',
    highlight:
      'Như lời hẹn trước, Savor Cake trở lại Làng trẻ em SOS Hà Nội với những chiếc bánh sinh nhật và nhiều món quà ý nghĩa dành cho các bạn nhỏ, các mẹ có ngày sinh nhật trong quý 2 (tháng 4, 5 và 6), đồng thời là thông điệp chúc mừng kỉ niệm ngày quốc tế thiếu nhi 1/6.',
    htmlContent: eventsList.event1,
    featured: true,
  },
  {
    id: 'event-2',
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/images/events/huong-vi-ngot-ngao.jpg"
        alt="event"
        placeholder="blurred"
      />
    ),
    title: 'Chương trình thiện nguyện "Hương Vị Yêu Thương”',
    featured: true,
  },
  {
    id: 'event-3',
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/images/events/giot-hong-gan-ket.jpg"
        alt="event"
        placeholder="blurred"
      />
    ),
    title: 'Nhà tài trợ chương trình thiện nguyện "Giọt hồng gắn kết"',
    featured: true,
  },
]
// const duplicateEventIds = socialEvents
//   .filter(
//     (event, index, self) => index !== self.findIndex((e) => e.id === event.id)
//   )
//   .map((event) => event.id)

export const hightlightEvents = filter(socialEvents, { featured: true })
