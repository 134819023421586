import React from 'react'
import 'twin.macro'
import { motion } from 'framer-motion'
import ImageZoom from 'react-medium-image-zoom'

import { Heading2, SectionContainer, BorderBox } from './Base'
import { containerStyle } from '../Base'
import { hightlightEvents } from '../../utils/social-events'
import { buttonVariants } from '../../utils/motion'

import '../../styles/staticImageInlineStyles.css'
import { navigate } from 'gatsby'
import { BubbleBotLeft } from '../Locations'
import { IconBot } from './CustomerFeedback'

const BodyEventsSection = () => {
  return (
    <section tw="relative z-10 h-full pt-6 pb-2" id="social-event">
      {/* wave + container */}
      <div css={containerStyle}>
        <div tw="flex flex-col justify-center items-center">
          <Heading2>Hoạt động xã hội</Heading2>
          <BorderBox />
        </div>
        <div
          tw="pt-6 flex flex-col md:flex-row justify-center items-center
            gap-2 lg:gap-10 w-full
            z-10 bg-transparent mx-auto
            "
        >
          {hightlightEvents.map((item, index) => (
            <div
              key={index}
              tw="border-4 border-solid border-savor-cake-primary-mint-0 w-5/6 sm:w-2/3 md:w-1/3 mx-auto rounded-lg"
            >
              <div tw="flex flex-col justify-center items-center">
                <ImageZoom>{item.image}</ImageZoom>
                <a
                  tw="text-savor-cake-primary-green-avocado-100 text-sm md:text-base lg:text-lg py-2 px-4 font-semibold text-center bg-savor-cake-primary-beige-0 rounded-b-md cursor-pointer"
                  href="/hoat-dong-xa-hoi/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {item.title}
                </a>
              </div>
            </div>
          ))}
        </div>
        {/* view more button */}
        <div tw="flex justify-center w-full mt-3">
          <motion.div variants={buttonVariants} whileHover="hover">
            <button
              tw="row-span-1 row-start-5 w-52 h-12
              text-lg lg:text-xl
              rounded-100
              text-savor-cake-primary-green-avocado-0 bg-savor-cake-primary-beige-0
              border-3 border-solid border-savor-cake-primary-mint-0
              hover:text-white hover:bg-savor-cake-primary-green-avocado-0
              m-auto
              font-savor-cake-primary-lexend font-semibold
              mb-10 mt-2 sm:mt-2 lg:mt-8
              lg:col-span-6 lg:row-span-1 lg:col-start-1
              cursor-pointer
              uppercase
              flex justify-center items-center
            "
              onClick={() => {
                navigate('/hoat-dong-xa-hoi/')
              }}
            >
              Xem thêm {'>'}
            </button>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

const SocialEventsSection = () => {
  return (
    <SectionContainer background="#FEFBF0">
      {/* sub */}
      <BubbleBotLeft customStyle={{ top: '0%', width: '11%' }} />
      <IconBot customStyle={{ width: '20vw' }} />
      {/* main */}
      <BodyEventsSection />
    </SectionContainer>
  )
}
export default SocialEventsSection
