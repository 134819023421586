import { useEffect } from 'react'
import { forEach } from 'lodash'

const useInViewTime = ({ targetSections }) => {
  useEffect(() => {
    const sectionTimes = {}

    const observerCallback = (entries) => {
      const currentTime = Date.now()
      forEach(entries, (entry) => {
        const sectionId = entry.target.id
        if (entry.isIntersecting) {
          sectionTimes[sectionId] = { startTime: currentTime, inViewTime: 0 }
        } else {
          if (sectionTimes[sectionId]) {
            sectionTimes[sectionId].inViewTime +=
              currentTime - sectionTimes[sectionId].startTime

            if (typeof window.gtag === 'function') {
              // console.log(sectionId, sectionTimes[sectionId].inViewTime)
              if (process.env.NODE_ENV !== 'development') {
                window.gtag('event', 'observe_in_view', {
                  event_label: sectionId,
                  in_view_section_id: sectionId,
                  in_view_time: sectionTimes[sectionId].inViewTime,
                })
                // console.log('ok')
              }
            }

            delete sectionTimes[sectionId]
          }
        }
      })
    }

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold as needed
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)

    forEach(targetSections, (section) => {
      observer.observe(section)
    })

    return () => {
      observer.disconnect()
    }
  }, [targetSections])
}

export default useInViewTime
