import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const ChocolateImage = (
  <StaticImage
    style={{ width: '80%' }}
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/chocolate.png"
    alt="chocolate"
  ></StaticImage>
)
const CoffeeImage = (
  <StaticImage
    style={{ width: '80%' }}
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/coffee-bean.png"
    alt="coffee bean"
  ></StaticImage>
)
const StrawberryImage = (
  <StaticImage
    style={{ width: '80%' }}
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/strawberry.png"
    alt="strawberry"
  ></StaticImage>
)
const CherriesImage = (
  <StaticImage
    style={{ width: '80%' }}
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/cherries.png"
    alt="cherries"
  ></StaticImage>
)
const BlueberryImage = (
  <StaticImage
    style={{ width: '80%' }}
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/blueberry.png"
    alt="blueberry"
  />
)
const GreenTeaImage = (
  <StaticImage
    style={{ width: '80%' }}
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/green-tea.png"
    alt="green tea"
  ></StaticImage>
)
const MangoImage = (
  <StaticImage
    style={{ width: '80%' }}
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/order-section/mango-cut.png"
    alt="mango"
  ></StaticImage>
)

const CakeOrder__Desktop = [
  {
    title: 'Vị cốt bánh',
    orders: [
      {
        key: 'desktop_socola',
        image: ChocolateImage,
        content: 'Vị Socola',
      },
      {
        key: 'desktop_coffee',
        image: CoffeeImage,
        content: 'Vị Cà phê',
      },
      {
        key: 'desktop_green-tea',
        image: GreenTeaImage,
        content: 'Vị Trà xanh',
      },
    ],
  },
  {
    title: 'Vị mứt bánh',
    orders: [
      {
        key: 'desktop_blueberry',
        image: BlueberryImage,
        content: 'Vị Việt quất',
      },
      {
        key: 'desktop_strawberry',
        image: StrawberryImage,
        content: 'Vị Dâu tây',
      },
      {
        key: 'desktop_mango',
        image: MangoImage,
        content: 'Vị Xoài',
      },
      {
        key: 'desktop_cherries',
        image: CherriesImage,
        content: 'Vị Cherry',
      },
    ],
  },
]

const CakeOrder__Mobile = [
  {
    title: 'Lựa chọn vị bánh',
    orders: [
      {
        key: 'mobile1',
        image: BlueberryImage,
        content: (
          <div>
            Cốt Vani <br />+<br /> Mứt Việt Quất
          </div>
        ),
      },
      {
        key: 'mobile2',
        image: StrawberryImage,
        content: (
          <div>
            Cốt Vani <br />+<br /> Mứt Dâu Tây
          </div>
        ),
      },
      {
        key: 'mobile3',

        image: MangoImage,
        content: (
          <div>
            Cốt Vani <br />+<br /> Mứt Xoài (kèm Xoài Tươi)
          </div>
        ),
      },
      {
        key: 'mobile4',

        image: CherriesImage,
        content: (
          <div>
            Cốt Vani <br />+<br /> Mứt Cherry
          </div>
        ),
      },
      {
        key: 'mobile5',

        image: ChocolateImage,
        content: (
          <div>
            Cốt Socola <br />+<br /> Kem Socola
          </div>
        ),
      },
      {
        key: 'mobile6',
        image: CoffeeImage,
        content: (
          <div>
            Cốt Cà Phê <br />+<br /> Kem Cà Phê
          </div>
        ),
      },
      {
        key: 'mobile7',

        image: GreenTeaImage,
        content: (
          <div>
            Cốt Trà Xanh <br />+<br /> Kem Trà Xanh
          </div>
        ),
      },
    ],
  },
]

export { CakeOrder__Desktop, CakeOrder__Mobile }
