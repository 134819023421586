import React from 'react'
import 'twin.macro'
import ScrollButtons from './ScrollButtons'
import CartIcon from '../Base/CartIcon'

const ButtonRightButtons = () => {
  return (
    <>
      <ScrollButtons />
      <CartIcon />
    </>
  )
}

export default ButtonRightButtons
