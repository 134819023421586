import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { StaticImgSectionWrapper } from './Base'
import { StaticBgImageStyle } from '../Base'
import useMediaQuery from '../../hooks/useMediaQuery'
import CollectionSection from './CollectionSection'

const CollectionMousse = () => {
  const isMediumScreen = useMediaQuery('(min-width: 768px)')
  return (
    <StaticImgSectionWrapper>
      {/* background */}
      {!isMediumScreen && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/mousse-bg-mobile.png"
          alt="Background bánh mousse"
          style={StaticBgImageStyle}
          placeholder="blurred"
        />
      )}
      {isMediumScreen && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/mousse-bg.png"
          alt="Background bánh mousse"
          style={StaticBgImageStyle}
          placeholder="blurred"
        />
      )}
      {/* wave + container */}
      <div>
        {/* {!isMediumScreen && (
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/combo-bot-wave-mobile.png"
            alt="wave"
            layout="fullWidth"
            placeholder="blurred"
          />
        )}
        {isMediumScreen && (
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/product-related-sections/combo-bot-wave.png"
            alt="wave"
            layout="fullWidth"
            placeholder="blurred"
          />
        )} */}
        {/* main slider */}
        <CollectionSection
          variant={'mousse'} // để phân biệt với khi để trong product groups danh mục sản phẩm thay vì để riêng 1 collection section
          id="scake-savor-cake-main-mousse-collection"
          showId="scake-mousse"
        />
      </div>
    </StaticImgSectionWrapper>
  )
}

export default CollectionMousse
